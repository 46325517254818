import { WagmiConfig, createConfig } from 'wagmi';
import { getDefaultConfig } from 'connectkit';
import UnityComponent from './Components/Unity/UnityComponent';
import React from 'react';
import styles from './App.module.css';
import { goerli, mainnet } from '@wagmi/chains';
import Popup from './Components/Popup/Popup';
// import { Connectors } from './Components/Connectors';

const appName = "Game";

function App() {
  const config = createConfig(
    getDefaultConfig({
      // Required API Keys
      alchemyId: process.env.REACT_APP_ALCHEMY_ID ?? "",
      //infuraId: process.env.REACT_APP_INFURA_ID ?? "",
      walletConnectProjectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID ?? "",

      // Required
      appName: appName,
      chains: [mainnet, goerli]
    }),
  );

  return (
    <div className={styles.container}>
      {/* <button onClick={clearSession}> Clear storage </button> */}

      <WagmiConfig config={config}>
        {/* <Connectors /> */}
          <UnityComponent appName={appName} />
      </WagmiConfig>
      {/* <Popup />  */}
    </div>
  );
}

export default App;
