import React, { useCallback, useEffect, useState } from 'react';
import { ConnectKitProvider, SIWEProvider } from 'connectkit';
import { useAccount, useDisconnect } from 'wagmi';
import { SignInConnectKitButton } from '../ConnectKitButton/SignInConnectKitButton';
import TransactionComponent from '../TransactionComponent';
import Modal from '../Modal/Modal';

function SIWEProviderComponent({ sendMessage, isLoaded, addEventListener, removeEventListener }) {
    const dataToSign = 'Ethereum Sign In: Burger Game';
    const account = useAccount();
    const disconnectData = useDisconnect();
    const [isReadyForConnect, setIsReadyForConnect] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(false);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalText, setModalText] = useState('Please open your wallet and Sign');

    const closeModal = () => {
        setIsModalVisible(false);
    };

    const handleSignature = ({ message, signature }) => {
        setIsModalVisible(false);
        const token = message + '_' + account.address + '_' + signature;
        localStorage.setItem('qbrr-locl-act', token);
        sendMessage("ReactBridgeController", "SetToken", token);
        setIsSignedIn(true);

        return true;
    }

    const siweConfig = {
        getNonce: () => ("1"),
        createMessage: ({
            nonce,
            address,
            chainId,
        }) => {
            setIsModalVisible(true); 
            return dataToSign;
        },
        verifyMessage: handleSignature,
        getSession: () => ({ address: account.address, chainId: account.chainId }),
        signOut: () => {
            handleDisconnectWallet();
            return true;
        }
    };

    const handleReadyForWalletConnection = useCallback(async () => {
        setIsReadyForConnect(true);

        const token = localStorage.getItem('qbrr-locl-act');
        setIsSignedIn(token !== null && token.trim() !== "");
        console.log(token);
        if (token !== null && token.trim() !== "")
            sendMessage("ReactBridgeController", "SetToken", token);

    }, [sendMessage, setIsSignedIn])

    const handleDisconnectWallet = useCallback(async () => {
        try {
            var disconect = await disconnectData.disconnectAsync();
            localStorage.removeItem('qbrr-locl-act');
            sendMessage("ReactBridgeController", "Disconnect");
            setIsSignedIn(false);
        } catch (error) {
            console.error(error.message);
        }
    }, [disconnectData, sendMessage, setIsSignedIn])


    useEffect(() => {
        addEventListener("ReadyForWalletConnection", handleReadyForWalletConnection);
        addEventListener("DisconnectWallet", handleDisconnectWallet);

        return () => {
            removeEventListener("ReadyForWalletConnection", handleReadyForWalletConnection);
            removeEventListener("DisconnectWallet", handleDisconnectWallet);
        };
    }, [addEventListener, removeEventListener, handleReadyForWalletConnection, handleDisconnectWallet]);

    return (
        <SIWEProvider {...siweConfig} >
            <ConnectKitProvider>
                {isLoaded && isReadyForConnect && !isSignedIn && (
                    <SignInConnectKitButton />
                )}
                <Modal isOpen={isModalVisible} onClose={closeModal}>
                    {modalText}
                </Modal>
                <TransactionComponent
                    sendMessage={sendMessage}
                    addEventListener={addEventListener}
                    removeEventListener={removeEventListener}
                />
            </ConnectKitProvider>
        </SIWEProvider>
    );
}

export default SIWEProviderComponent;
