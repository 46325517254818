import styled from "styled-components";

export const StyledButton = styled.button`
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding: 6% 11%;
  color: ${props => (props.isConnected ? "#303030" : "#ffffff")};
  background-color: ${props => (props.isConnected ? "transparent" : "#EB5528")};
  border-radius: 10px; /* Делаем кнопку круглой */
  font-size: 24px;
  font-weight: bold;
  white-space: nowrap;
  border: none; /* Убираем обводку */
  transition: 200ms ease;
  z-index: 1000;
  
  @media (orientation: portrait) {
    font-size: 4vw; /* Измененный размер шрифта для вертикальных экранов */
  }

  &:hover {
    transform: translateY(-6px);
  }

  &:active {
    transform: translateY(-3px);
  }
`;

export const ButtonContainer = styled.div`
  position: fixed;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;