import React from 'react';
import './Popup.css';
import { isMobile, isFirefox, isChrome, isOpera, isSafari, isIOS, isAndroid } from 'react-device-detect';

const Popup = () => {
    const isMobileBrowser = (isIOS || isAndroid) && (isFirefox || isChrome || isOpera || isSafari);

  if (!isMobileBrowser) {
    // Не показывать окно на десктопе
    return null;
  }

  const handleMetaMaskLinkClick = () => {
    window.location.href = 'https://metamask.app.link/dapp/test.game.ivklim.com';
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <p>
        To play the Match 3 Game on a mobile device, click the 'Open in MetaMask Browser' button.
        </p>
        <button
            onClick={handleMetaMaskLinkClick}
            style={{
              backgroundColor: '#EB5528',
              color: 'white',
              border: 'none',
              padding: '10px 20px',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Open in MetaMask Browser
          </button>
      </div>
    </div>
  );
};

export default Popup;
