import { useState } from 'react';
import { Unity, useUnityContext } from 'react-unity-webgl';
import LoadingComponent from './LoadingComponent';
import ReactAPIHandler from '../ReactAPIHandler/ReactAPIHandler';
import styles from './UnityComponent.module.css';
import { SignInConnectKitButton } from '../Wallet/ConnectKitButton/SignInConnectKitButton';
import { ConnectKitProvider, SIWEProvider } from 'connectkit';
import SIWEProviderComponent from '../Wallet/SIWEProviderComponent/SIWEProviderComponent';

function UnityComponent({ appName }) {
    const {
        unityProvider,
        isLoaded,
        loadingProgression,
        addEventListener,
        removeEventListener,
        sendMessage
    } = useUnityContext({
        loaderUrl: "./unitybuild/" + appName + ".loader.js",
        dataUrl: "./unitybuild/" + appName + ".data.gz",
        frameworkUrl: "./unitybuild/" + appName + ".framework.js.gz",
        codeUrl: "./unitybuild/" + appName + ".wasm.gz",
        // loaderUrl: "./unitybuild/" + appName + ".loader.js",
        // dataUrl: "./unitybuild/" + appName + ".data",
        // frameworkUrl: "./unitybuild/" + appName + ".framework.js",
        // codeUrl: "./unitybuild/" + appName + ".wasm",
        webglContextAttributes: {
            preserveDrawingBuffer: true,
        },
    });

    return (
        <div className={styles.unityWrapperBG}>
            <>{window.ethereum?.isMetaMask}</>
            <LoadingComponent
                isLoaded={isLoaded}
                loadingProgression={loadingProgression}
            />
            <Unity
                unityProvider={unityProvider}
                devicePixelRatio={2}
                style={{
                    display: isLoaded ? "block" : "none",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    maxWidth: "100%",
                    maxHeight: "100%",
                }}
            />
            <ReactAPIHandler
                addEventListener={addEventListener}
                removeEventListener={removeEventListener}
            />
            <SIWEProviderComponent
                sendMessage={sendMessage}
                isLoaded={isLoaded}
                addEventListener={addEventListener}
                removeEventListener={removeEventListener}
            >
            </SIWEProviderComponent>
        </div>
    );
}

export default UnityComponent;