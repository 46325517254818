import { ConnectKitButton } from 'connectkit';

import { StyledButton, ButtonContainer } from './ButtonStyles';



export const SignInConnectKitButton = () => {
  return (
    <ButtonContainer>
      <ConnectKitButton.Custom >
        {({ isConnected, show, truncatedAddress, ensName }) => {
          return (
            <StyledButton
              onClick={show}
            >
              {isConnected ? "Sign In to Play" : "Connect Your Wallet to Play"}
            </StyledButton>
          );
        }}
      </ConnectKitButton.Custom>
    </ButtonContainer>
  );
};