import React, { useCallback, useEffect, useState } from 'react';
import { erc20ABI, useAccount, useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import { parseUnits } from 'ethers';

import LoadingSpinner from './LoadingSpinner/LoadingSpinner';
import GreenCheckmarkIcon from './GreenCheckmarkIcon/GreenCheckmarkIcon';

import styles from './../Unity/UnityComponent.module.css';
import Modal from './Modal/Modal';

const TransactionComponent = ({ sendMessage, addEventListener, removeEventListener }) => {

    const [isClickedTransfer, setClickedTransfer] = useState(false);
    const [tokenContractAddress, setTokenContractAddress] = useState("");
    const [recipientAddress, setRecipientAddress] = useState("");
    const [tokenAmount, setTokenAmount] = useState("0");
    const account = useAccount();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalText, setModalText] = useState('Please open your wallet and Sign');

    const closeModal = () => {
        setIsModalVisible(false);
    };

    const { config, error } = usePrepareContractWrite({
        address: tokenContractAddress,
        abi: erc20ABI,
        functionName: 'transfer',
        args: [recipientAddress, tokenAmount ? parseUnits(tokenAmount, 18) : undefined]
    })
    const { data, write } = useContractWrite(config)

    const { isLoading } = useWaitForTransaction({
        hash: data?.hash,
        onSettled(data, error) {
            console.log('Settled', { data, error })
            statusUpdated(data?.status);
            sendMessage("ReactBridgeController", "OnTicketsBought", "");
        }
    })

    useEffect(() => {
        if (isLoading || !write || !recipientAddress || !tokenAmount || !isClickedTransfer) {
            return;
        }
        setClickedTransfer(false);

        write?.();
        setIsModalVisible(true);

    }, [isLoading, isClickedTransfer, write, tokenContractAddress, recipientAddress, tokenAmount]);

    useEffect(() => {
        if (error !== null && error.message !== null && error.message?.includes("transfer amount exceeds balance")) {
            alert(
                "There is not enough $FLRBRG in this account. Please read how to top up at https://flrbrg.io/ or click 'Rules'"
            );
        }
        console.error("config error: " + error);
        setIsModalVisible(false);
    }, [error])


    const sendTokens = useCallback((
        userLogginedAddress,
        tokenContractAddress,
        recipientAddress,
        tokenAmount
    ) => {
        setTokenContractAddress(tokenContractAddress);
        setRecipientAddress(recipientAddress);
        setTokenAmount(tokenAmount);

        if (!account.isConnected || (account.address.toLowerCase() !== userLogginedAddress.toLowerCase())) {
            alert(
                'Please log in or switch account to wallet with the same address as in the game. \n' +
                "Address should be: " + userLogginedAddress
            );
            return;
        }

        setClickedTransfer(true);
    }, [account.address, account.isConnected]);

    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const [buttonText, setButtonText] = useState('');
    const successText = 'Success';
    const pendingText = 'Pending';
    const errorText = 'Error';

    useEffect(() => {
        if (isLoading) {
            setIsButtonVisible(true);
            setButtonText(pendingText);
            setIsModalVisible(false);
        }
    }, [isLoading]);

    async function statusUpdated(status) {
        if (status === 'success') {
            setButtonText(successText);
            setTimeout(() => {
                setIsButtonVisible(false);
            }, 2000);
            setIsModalVisible(false);
        } else if (status === 'error') {
            setButtonText(errorText);
            setTimeout(() => {
                setIsButtonVisible(false);
            }, 2000);
            setIsModalVisible(false);
        } else {
            setIsButtonVisible(false);
            setIsModalVisible(false);
        }
    }

    useEffect(() => {
        addEventListener("BuyTicketsForTokens", sendTokens);
        return () => {
            removeEventListener("BuyTicketsForTokens", sendTokens);
        };
    }, [addEventListener, removeEventListener, sendTokens]);

    return (
        <>
            <Modal isOpen={isModalVisible} onClose={closeModal}>
                {modalText}
            </Modal>
            {isButtonVisible && (
                <button
                    disabled={true}
                    className={styles.pendingBtn}
                >
                    {buttonText}
                    {buttonText === pendingText && <LoadingSpinner />} {/* Покажите крутящийся круг загрузки */}
                    {buttonText === successText && <GreenCheckmarkIcon />} {/* Покажите галочку */}
                </button>
            )}
        </>
    );
};

export default TransactionComponent;
